<template>
  <div id="ftp">
      <div class="great-bk d-none d-md-block">
          <div class="container">
              <div class="row">
                  <div class="col-8">&nbsp;</div>
              </div>
          </div>
      </div>
      <div class="container position-relative">
          <div class="row justify-content-end">
              <div class="col-12 col-md-6 align-self-end">
                  <form action="login5.php" method="post" target="_blank">
                      <p class="text-center">Bienvenue sur notre FTP !</p>
                      <div class="sign-title text-center mb-5">CONNEXION</div>
                      <div class="group">
                          <input type="text" @change="Changed"/>
                          <label><img src="../assets/images/icon/login.svg" alt="">Votre identifiant</label>
                      </div>
                      <div class="group">
                          <input type="password" @change="Changed"/>
                          <label><img src="../assets/images/icon/lock-alt.svg" alt="">Votre mot de passe</label>
                      </div>
                      <button type="submit" class="bt-hover mt-4">SE CONNECTER</button>
                  </form>
              </div>
          </div>

      </div>
  </div>
</template>

<script>
    export default {
        methods: {
            Changed: function ({ target }) {
                if (target.value == '') {
                    target.classList.remove("doneLabel");
                } else {
                    target.classList.add("doneLabel");
                }

            }
        }
    }
</script>

<style lang="scss" scoped>
    #ftp{
        padding-top: 100px;
    }

    /* */
    .great-bk {
        position: absolute;
        width: 100%;

        .col-8{
            /*background-color: #0000fe;*/
            border-radius: 20px;
            min-height: 680px;
            background-image: url("../assets/images/great-bk.jpg");
            background-position: center;
        }
    }

    /* */

    .sign-title{
        font-size: 42px;
        font-weight: bold;
    }

    /* form */
    form{
        color: #131314;
        width: 100%;
        max-width: 440px;
        margin: 4em auto;
        padding: 3em 2em 2em 2em;
        background: #fafafa;
        border-radius: 20px;
        border: 1px solid;
    }

    .group{
        position: relative;
        margin-bottom: 25px;
    }

    input{
        font-size: 16px;
        padding: 10px 10px 10px 24px;
        display: block;
        color: #131314;
        width: 100%;
        border: 1px solid #757575;
        border-radius: 0;
    }

    input:focus {
        outline: none;
    }

    /* label */
    label{
        color: #999;
        font-size: 16px;
        font-weight: normal;
        position: absolute;
        pointer-events: none;
        left: 5px;
        top: 10px;
        background: white;
        padding: 0 10px;
        transition: all 0.2s ease;

        img{
            margin-right: 10px;
            transition: all 0.2s ease;
        }
    }

    /* active */

    input:focus ~ label,
    input.used ~ label,
    .doneLabel ~ label {
        top: -12px;
        transform: scale(.75); left: 4px;
        color: #131314;

        img{
            opacity: 0;
            width: 0;
            margin-right: 0;
        }
    }


    /* Animations */

    @keyframes inputHighlighter {
        from { background: #4a89dc; }
        to 	{ width: 0; background: transparent; }
    }

    /* Button */
    .bt-hover{
        display: inline-block;
        position: relative;
        width: 100%;
        background: none;
        padding: 12px 24px;
        margin: .3em 0 1em 0;
        letter-spacing: 1px;
        font-weight: 500;
        font-size: 16px;
        outline: none;
        border: 1px solid #131314;
        transition: all 0.3s;

        &:hover{
            background: #131314;
            color: #fff;
        }

        &:focus { outline: 0; }
    }

</style>
